import React from "react"
import AnyLink from "../../hoc/anyLink/AnyLink"
import Classes from './fullVideo.module.scss'
import Img from 'gatsby-image'

const FullVideo = ({ title, link, linkTitle, text, image }) => {

    const textEl = (
        <div className="col col-xs-12 col-md-6 text-center space-xs-up">
            <h2>{ title }</h2>
            <p>{ text }</p>
        </div>
    )

    const imageEl = (
        <div className="col col-xs-12 col-md-8 space-xs space-xs-up">
            {/* <img src={Image}/> */}
            {image  ?
            <Img fluid={image} className={Classes.image} />
            : 
            <div className={["embed-responsive", Classes.video].join(' ')}>
                <iframe src="https://www.youtube.com/embed/4bh2L64a9gc?autoplay=0&mute=0&loop=0&controls=0&rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            }
            
           
        </div>
    )

    return (
    <section className="section-padding">
        <div className="container">
            <div className="row center-xs middle-xs">
                {textEl}
                {imageEl}
                <div className="col col-xs-12 text-center">
                    <AnyLink className="btn btn-large btn-primary " to={ link }>{ linkTitle }</AnyLink>
                </div>
            </div>
        </div>
    </section>
)}

export default FullVideo


