import React from "react"
import { Link } from "gatsby-plugin-intl"
import Classes from './videoText.module.scss'
import AnyLink from "../../hoc/anyLink/AnyLink"
import Notification from "../../hoc/notification/notification"

const videoText = ({ image, textLeft, title, text, link, linkTitle, topSway, largeCta, primaryCta, h1, classes }) => {

    const textEl = (
        <div className="col col-xs-12 col-md-6 col-lg-4 text-center-xs text-left-md">
            {h1 ?
            <h1>{ title }</h1>
            : 
            <h2>{ title }</h2>
            }
            
            <p>{ text }</p>
            <AnyLink className={[largeCta ? "btn btn-large" : "link-arrow", primaryCta ? "btn-primary" : "btn-secondary"].join(' ')} to={ link }>{ linkTitle }</AnyLink>
        </div>
    )

    const imageEl = (
        <div className="col col-xs-12 col-md-6 col-lg-6 space-xs space-sm">
            <div className={[Classes.image, "embed-responsive"].join(' ')}>
            <iframe src="https://www.youtube.com/embed/IVlIV5GdmTE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            </div>     
        </div>
    )

    return (
    <section className={["section-padding", classes].join(' ')}>
        
        
        <div className="container">
        {h1 ?
            <div className="row center-xs middle-xs">
                <div className="col col-xs-12 col-md-8 col-lg-6 space-xs-up">
                    <Notification />
                </div>
            </div>
        : null}
            <div className={["row center-xs middle-xs", textLeft ? "reverse-xs reverse-sm row-md" : null].join(' ')}>
                { textLeft ? textEl : imageEl }
                { textLeft ? imageEl : textEl }
            </div>
        </div>
    </section>
    // &loop=1&showinfo=0&rel=0&theme=light&controls=0
)}

export default videoText
