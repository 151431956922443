import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import ThreeUp from "../components/sections/ThreeUp/ThreeUp"
import ImageText from "../components/sections/ImageText/ImageText"
import VideoText from "../components/sections/videoText/videoText"
import FullVideo from '../components/sections/fullVideo/fullVideo'
import ImageGallery from "../components/sections/imageGallery/imageGallery"

const Product = ({data}) => {
    const templateModern = data.templateModern.childImageSharp.fluid
    const templateClassic = data.templateClassic.childImageSharp.fluid
    const editor = data.editor.childImageSharp.fluid
    const webDesign = data.webDesign.childImageSharp.fluid
    const unsplash = data.unsplash.childImageSharp.fluid
    const colors = data.colors.childImageSharp.fluid
    const i18n = useIntl().formatMessage

    return (
    <>
        <SEO title="Produktet" />
        <VideoText 
            image={editor} 
            textLeft={true} 
            title="Worksiter gør det nemt at have en hjemmeside"
            text="Vi har fjernet alle unødvendige indstillinger og lavet forslag til tekster, billeder og opbygning til netop din branche." 
            linkTitle="Prøv gratis"
            link="/register" 
            largeCta
            primaryCta
            h1
            classes="bg-lightblue"
            />
        <ThreeUp 
            title={i18n({ id: "pages.index.three_up.title" })} 
            elements={
                [
                {
                    title: i18n({ id: "pages.index.three_up.texts.1.title" }),
                    text: i18n({ id: "pages.index.three_up.texts.1.text" })
                },
                {
                    title: i18n({ id: "pages.index.three_up.texts.2.title" }),
                    text: i18n({ id: "pages.index.three_up.texts.2.text" })
                },
                {
                    title: i18n({ id: "pages.index.three_up.texts.3.title" }),
                    text: i18n({ id: "pages.index.three_up.texts.3.text" })
                }
                ]
            }
            cta={[
              {link: "/register", linkTitle: "Prøv det"}
            ]}
            />
        <ImageText 
            image={webDesign} 
            textLeft={false} 
            title="Færdig side med indhold"
            text="Når du opretter dig har vi allerede lavet din side for dig, komplet med undersider, tekster, billeder og kontaktformular der passer til dit branchevalg, og som du nemt kan tilpasse til din virksomhed."
            linkTitle={i18n({ id: "pages.index.image_text.link_title" })}
            link="/register" 
            />
        <ImageGallery
            title="Skil dig ud"
            text="Vores designskabeloner er optimeret til alle skærmstørrelser. Så uanset om dine kunder besøger dig på mobil, ipad eller computer, kan du være sikker på, at din virksomhed ser professionel ud."
            images={[{image: templateModern, title: "Moderne"}, {image: templateClassic, title: 'Klassisk'}]}
            />
        <ImageText 
            image={editor} 
            textLeft={true} 
            title="Nem redigering af indhold"
            text="Du kan nemt redigere, omarrangere og slette indholdet på din side. Du kan se dine ændringer live, og hele tiden se, hvordan din side ser ud. Den visuelle editor kræver intet kendskab til hjemmesider eller teknisk snilde."
            linkTitle={i18n({ id: "pages.index.image_text.link_title" })}
            link="/register" 
            />
       

        <FullVideo
            title="Indbygget billedsøgning" 
            text="Har du ikke lige en masse billeder liggende? Intet problem. Vælg mellem tusindevis af professionelle billeder i det indbyggede billedekartotek. Brug vores foreslået søgeord til at finde billeder der passer til netop din virksomhed." 
            linkTitle={i18n({ id: "pages.index.full_video.link_title" })} 
            link="/register"
            image={unsplash}
        />
         <ImageText 
            image={colors} 
            textLeft={false} 
            title="Gør din side til din"
            text="Vælg en af vores farvepaletter eller brug dine egne farver. Farvepalletten bestemmer hele hjemmesidens farver, så du kun skal indstille det ét sted, én gang."
            linkTitle={i18n({ id: "pages.index.image_text.link_title" })}
            link="/register" 
            />
    </>
    )
    }

export default Product

export const query = graphql`
query {
  webdesigniphone: file(relativePath: { eq: "modern_design_mobile.png" }) {
    childImageSharp {
      fluid(maxWidth: 600, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  editor: file(relativePath: { eq: "editor.png" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  webDesign: file(relativePath: { eq: "classic_design_contact.png" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  templateModern: file(relativePath: { eq: "modern_design.png" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  templateClassic: file(relativePath: { eq: "classic_design.png" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  unsplash: file(relativePath: { eq: "dummy_content/craft.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  colors: file(relativePath: { eq: "dummy_content/colors.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
}
`