import React from "react"
import { Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import AnyLink from "../../hoc/anyLink/AnyLink"


import ImageTextStyles from './ImageText.module.scss'

const ImageText = ({ image, textLeft, title, text, link, linkTitle, topSway, largeCta, primaryCta }) => {

    const textEl = (
        <div className="col col-xs-12 col-md-6 col-lg-4 text-center-xs text-left-md">
            <h2>{ title }</h2>
            <p>{ text }</p>
            <AnyLink className={[largeCta ? "btn btn-large" : "link-arrow", primaryCta ? "btn-primary" : "btn-secondary"].join(' ')} to={ link }>{ linkTitle }</AnyLink>
        </div>
    )

    const imageEl = (
        <div className="col col-xs-12 col-md-6 col-lg-6 space-xs space-xs-up">
            <Img  fluid={image} className={ImageTextStyles.image} />              
        </div>
    )

    return (
    <section className={["section-padding", topSway ? "bg-lightblue" : null].join(' ')}>
        <div className="container">
            <div className={["row center-xs middle-xs ", textLeft ? "reverse-xs reverse-sm row-md" : null].join(' ')}>
                { textLeft ? textEl : imageEl }
                { textLeft ? imageEl : textEl }
            </div>
        </div>
    </section>
)}

export default ImageText
