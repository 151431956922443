import React from 'react'
import Img from "gatsby-image"

import Classes from './imageGallery.module.scss'

const ImageGallery = ({title, text, images}) => {

    return (
        <section className="section-padding">
            <div className="container">
                <div className="row center-xs middle-xs text-center">
                    <div className="col col-xs-12 col-md-6 space-xs-up">
                        <h2>{title}</h2>
                        <p>{text}</p>
                    </div>
                </div>
                <div className="row center-xs middle-xs text-center">
                    {images.map((img, i) => 
                        <div key={i} className="col col-xs-6 col-md-4">
                            <Img fluid={img.image} className={Classes.image}/>
                            <p className="small">{img.title}</p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default ImageGallery